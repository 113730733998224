<template>
    <footer class="footer mt-3">
      <div class="container mx-8 pb-0">
        <div class="footer-links pr-4">
            <!-- Social Media Links -->
            <h3 class="text-white">Comparez les prix des produits cartes Pokémon TCG</h3>
            <div class="social-media-links">
                <a href="https://twitter.com/TCGDetective" target="_blank" rel="noopener noreferrer">
                <Button icon="pi pi-twitter" class="p-button-rounded p-button-outlined" />
                </a>
                <a href="https://discord.gg/2bhPx4HBbF" target="_blank" rel="noopener noreferrer">
                <Button icon="pi pi-discord" class="p-button-rounded p-button-outlined" />
                </a>
                <a href="https://www.instagram.com/tcgdetective/" target="_blank" rel="noopener noreferrer">
                <Button icon="pi pi-instagram" class="p-button-rounded p-button-outlined" />
                </a>
            </div>
        </div>
        <p>&copy; 2024 TCG Detective</p>
      </div>
    </footer>
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  import Button from 'primevue/button';
  
  export default defineComponent({
    name: 'Footer',
    components: {
      Button
    },
    methods: {
      openLink(url) {
        window.open(url, '_blank');
      }
    }
  })
  </script>

<style scoped>
.footer {
    background-color: var(--tcg-blue);
    color: #fff;
    padding: 20px 0;
    position: relative;
    bottom: 0;
    width: 100%;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-links a {
    color: #fff;
    text-decoration: none;
}

.social-media-links {
  display: flex;
  gap: 10px;
}

.social-media-links .p-button {
  color: #fff;
  border-color: #fff;
}
</style>