<template>
  <div v-if="results && results.length > 0" class="search-results-overlay">
    <ul>
      <li v-for="result in results" :key="result.productId" @click="$emit('select', result.productId)"
        class="result-item pb-2">
        <img :src="getThumbnailUrl(result)" alt="Product thumbnail" class="product-thumbnail">
        <div class="product-name">{{ result.name }}</div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: {
    results: {
      type: Array,
      required: true
    }
  },
  setup() {
    const getThumbnailUrl = (result) => {
      return `${process.env.VUE_APP_API_ENDPOINT}/api/images/${result.collectionId}/${result.productId}_thumbnail.webp`;
    };

    return {
      getThumbnailUrl
    };
  }
}
</script>

<style scoped>
.search-results-overlay {
  position: absolute;
  right: 0;
  width: 100%;
  background-color: white;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.result-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.result-item:hover {
  background-color: #f0f0f0;
}

.product-thumbnail {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.product-name {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}</style>