import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    seriesTitles: {
      ev: 'Écarlate et Violet',
      eb: 'Épée et Bouclier',
    },
    tags: [],
    collections: [],
    collectionsResponse: [],
  },
  mutations: {
    setSeriesTitles(state, seriesTitles) {
      state.seriesTitles = seriesTitles;
    },
    setTags(state, tags) {
      state.tags = tags;
    },
    setCollections(state, collections) {
      state.collections = collections;
    },
    setCollectionsResponse(state, collectionsResponse) {
      state.collectionsResponse = collectionsResponse;
    },
  },
  actions: {
    updateSeriesTitles({ commit }, seriesTitles) {
      commit('setSeriesTitles', seriesTitles);
    },
    updateTags({ commit }, tags) {
      commit('setTags', tags);
    },
    updateCollections({ commit }, collections) {
      commit('setCollections', collections);
    },
    resetCollections({ commit }) {
      commit('setCollections', []);
    },
    resetTags({ commit }) {
      commit('setTags', []);
    },
    async fetchCollections({ commit, state }) {
      // Only fetch collections if they haven't been fetched yet
      if (state.collectionsResponse.length === 0) {
        const response = await axios.get(`${process.env.VUE_APP_API_ENDPOINT}/api/collections/`);
        commit('setCollectionsResponse', response.data);
      }
    },
  },
  getters: {
    seriesTitles: (state) => state.seriesTitles,
    tags: (state) => state.tags,
    collections: (state) => state.collections,
    collectionsResponse: (state) => state.collectionsResponse,
  },
});