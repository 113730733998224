<template>
  <div id="app">
    <PokeshopdexMenu class="menu mb-3"/>
    <div class="content">
      <router-view :key="$route.fullPath"></router-view>
    </div>
  </div>
  <Footer />
</template>

<script>
import PokeshopdexMenu from './components/PokeshopdexMenu.vue'
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    PokeshopdexMenu,
    Footer
  }
}
</script>

<style>
@media (min-width: 1024px) {
  html {
    overflow-y: scroll;
  }
}

.menu {
  max-width: 1560px;
  margin: 0 auto;
}

.p-menubar-button {
  order: -1;
}

a, a:hover, a:focus, a:active {
  color: inherit;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
</style>