import { createRouter, createWebHistory } from 'vue-router'

const HomePage = () => import('./components/HomePage.vue')
const PrivacyPolicy = () => import('./components/PrivacyPolicy.vue')
const CookiePolicy = () => import('./components/CookiePolicy.vue')
const Register = () => import('./components/Register.vue')
const Login = () => import('./components/Login.vue')
const CollectionPage = () => import('./components/CollectionPage.vue')
const ProductPage = () => import('./components/ProductPage.vue')
const CategoryPage = () => import('./components/CategoryPage.vue')
const Admin = () => import('./components/Admin.vue')
const AddWebsite = () => import('./components/AddWebsite.vue')
const AddCollection = () => import('./components/AddCollection.vue')
const SeriesPage = () => import('./components/SeriesPage.vue')
const NotFound = () => import('./components/NotFound.vue')

const routes = [
  { path: '/', component: HomePage },
  { path: '/confidentialite', component: PrivacyPolicy },
  { path: '/cookies', component: CookiePolicy },
  { path: '/register', component: Register },
  { path: '/login', component: Login },
  { path: '/cartes-pokemon/collections/:id', component: CollectionPage },
  { path: '/cartes-pokemon/products/:productId', component: ProductPage },
  { path: '/cartes-pokemon/category/:categoryId', component: CategoryPage },
  { path: '/admin', component: Admin },
  { path: '/addwebsite', component: AddWebsite },
  { path: '/addcollection', component: AddCollection },
  { path: '/cartes-pokemon/collections', component: SeriesPage },{
    path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router