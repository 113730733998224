import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./style.css";
import "./flags.css";
import 'flag-icon-css/css/flag-icons.min.css'

import { createApp, defineAsyncComponent } from "vue";
import store from './store';
import { createHead } from '@unhead/vue'
import App from "./App.vue";
import router from './router'
import PrimeVue from "primevue/config";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import Tooltip from "primevue/tooltip";
import Skeleton from "primevue/skeleton";

const head = createHead()
const Button = defineAsyncComponent(() => import("primevue/button"));
const Breadcrumb = defineAsyncComponent(() => import("primevue/breadcrumb"));
const Card = defineAsyncComponent(() => import("primevue/card"));
const Carousel = defineAsyncComponent(() => import('primevue/carousel'));
const Image = defineAsyncComponent(() => import("primevue/image"));
const Checkbox = defineAsyncComponent(() => import("primevue/checkbox"));
const DataView = defineAsyncComponent(() => import("primevue/dataview"));
const Dropdown = defineAsyncComponent(() => import("primevue/dropdown"));
const InputText = defineAsyncComponent(() => import("primevue/inputtext"));
const InputNumber = defineAsyncComponent(() => import("primevue/inputnumber"));
const MegaMenu = defineAsyncComponent(() => import("primevue/megamenu"));
const FloatLabel = defineAsyncComponent(() => import("primevue/floatlabel"));
const Toast = defineAsyncComponent(() => import("primevue/toast"));
const Sidebar = defineAsyncComponent(() => import("primevue/sidebar"));
const DataViewLayoutOptions = defineAsyncComponent(() => import("primevue/dataviewlayoutoptions"));
const Chip = defineAsyncComponent(() => import("primevue/chip"));

const app = createApp(App);

app.use(store);
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);
app.use(head);

app.component("Button", Button);
app.component("Breadcrumb", Breadcrumb);
app.component("Carousel", Carousel);
app.component("Card", Card);
app.component("Chip", Chip);
app.component("Checkbox", Checkbox);
app.component("DataView", DataView);
app.component("FloatLabel", FloatLabel);
app.component("InputNumber", InputNumber);
app.component("Image", Image);
app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("Dropdown", Dropdown);
app.component("InputText", InputText);
app.component("MegaMenu", MegaMenu);
app.component("Toast", Toast);
app.directive("tooltip", Tooltip);
app.component("Sidebar", Sidebar);
app.component("Skeleton", Skeleton);

app.mount("#app");