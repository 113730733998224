<template>
  <MenuBar :model="items" class="bg-white" :pt="{ root: { style: 'font-family: Viga; font-size: 1.3rem' }, submenu: { style: 'min-width: 280px' } }">
    <template #start>
      <span class="inline-flex align-items-center gap-1 px-2 py-2">
        <router-link to="/">
          <img src="@/../public/img/logo.svg" alt="logo" class="h-full w-8 mx-auto clickable" />
        </router-link>
      </span>
    </template>
    <template #item="{ item, props, hasSubmenu }">
        <router-link v-if="item.route" v-slot="{ href, navigate }" :to="item.route" custom>
            <a v-ripple :href="href" v-bind="props.action" @click="navigate">
                <span :class="item.icon" />
                <span>{{ item.label }}</span>
            </a>
        </router-link>
        <a v-else v-ripple :href="item.url" :target="item.target" v-bind="props.action">
            <span :class="item.icon" />
            <span>{{ item.label }}</span>
            <span v-if="hasSubmenu" class="pi pi-fw pi-angle-down ml-2" />
        </a>
    </template>
    <template #end>
      <div class="relative">
        <div class="flex align-items-center gap-2">
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="searchQuery" placeholder="Rechercher un produit" @input="showSearchResults = true" />
          </span>
        </div>
        <SearchResults v-if="showSearchResults" :results="searchResults" @select="selectProduct" />
      </div>
    </template>
  </MenuBar>
</template>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from 'vue-router';
import MenuBar from 'primevue/menubar';
import InputText from 'primevue/inputtext';
import { debounce } from 'lodash';
import SearchResults from './SearchResults.vue';

const router = useRouter();
const searchQuery = ref('');
const searchResults = ref([]);
const showSearchResults = ref(false);

const search = async () => {
  showSearchResults.value = true;
  const response = await fetch(`${process.env.VUE_APP_API_ENDPOINT}/api/products/search?query=${searchQuery.value}`);
  const data = await response.json();
  searchResults.value = Array.isArray(data) ? data : data.results;
};

const debouncedSearch = debounce(search, 500);

const selectProduct = (productId) => {
  goToProduct(productId);
  showSearchResults.value = false;
};

const handleClickOutside = (event) => {
  if (!event.target.closest('.relative')) {
    showSearchResults.value = false;
  }
};

watch(searchQuery, () => {
  debouncedSearch();
});

const items = ref([
  {
    label: 'Séries',
    route: '/cartes-pokemon/collections'
  },
  {
    label: 'Catégories',
    items: [
      {
        label: 'Display & Bundle',
        route: '/cartes-pokemon/category/display'
      },
      {
        label: 'Coffrets Dresseur d\'élite - ETB',
        route: '/cartes-pokemon/category/etb'
      },
      {
        label: 'Coffrets Collection',
        route: '/cartes-pokemon/category/collection'
      },
      {
        label: 'Tripacks',
        route: '/cartes-pokemon/category/tripack'
      },
      {
        label: 'Pokébox',
        route: '/cartes-pokemon/category/pokebox'
      },
      {
        label: 'Mini-tin',
        route: '/cartes-pokemon/category/mini-tin'
      },
      {
        label: 'Boosters',
        route: '/cartes-pokemon/category/booster'
      },{
        label: 'Tous les produits',
        route: '/cartes-pokemon/category/all'
      }
    ]
  }
]);

onMounted(async () => {
  document.addEventListener('click', handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener('click', handleClickOutside);
});

const goToProduct = (productId) => {
  router.push(`/cartes-pokemon/products/${productId}`);
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}

.h-full {
  max-height: 80px;
  margin: 0 !important;
  padding: 0 !important;
}

.p-submenu-list {
  width: 210px;
}
</style>